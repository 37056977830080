import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"

import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image"
import Text from "../hoc/text";

function PortfolioFicha({content, lang}){

    const {
		title, 
		cliente, 
		stack, 
		fecha, 
		discontinued, 
		web, 
		servicio, 
		slide, 
		introduccin,
		challengeTitulo,
		challengeTexto,
		imagenesExplicativas
	} = content

	console.log(content)

	let date = false
	let fLCapital = s => s.replace(/./, c => c.toUpperCase())

	if(fecha){
		date = new Date(fecha)
	}

	const settings = {
		arrows:false,
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay:true,
		adaptiveHeight:true,
		autoplaySpee:10000
	};

	const settings2 = {
		arrows:false,
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
		  {
			breakpoint: 1024,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			}
		  },
		  {
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			}
		  }
		]
	};

    return (
        <Fragment>

				<div className="project-content-v1 pf-single-layout3" style={{backgroundColor:'#e4edf0'}}>
					<div className="pf-head-v3">
						<div className="container">
							<div className="pf-head-content-v3 text-center">
								<h1>{title}</h1>
								<ul className="prp-list">
									{cliente ?
										<li>
											<h3><FormattedMessage  id="portfolioClientLabel" defaultMessage="Cliente" /></h3>
											<span>{cliente}</span>
										</li>
									: null}
									{stack ?
										<li>
											<h3><FormattedMessage  id="portfolioStackLabel" defaultMessage="Tecnologías" /></h3>
											<span>{stack}</span>
										</li>
									: null}
									{servicio ?
										<li>
											<h3><FormattedMessage  id="portfolioServicesLabel" defaultMessage="Servicios" /></h3>
											<span>{servicio}</span>
										</li>
									: null}
									{fecha ?
										<li>
											<h3><FormattedMessage  id="portfolioDateLabel" defaultMessage="Fecha" /></h3>
											<span>{fLCapital(date.toLocaleDateString(lang, {month: 'long', year: 'numeric'}))}</span>
										</li>
									: null}
									{discontinued ?
										<li>
											<h3><FormattedMessage  id="portfolioWebsiteLabel" defaultMessage="Sitios web" /></h3>
											<span>{discontinued}</span>
										</li>
									: 
										<li>
											<h3><FormattedMessage  id="portfolioWebsiteLabel" defaultMessage="Sitios web" /></h3>
											<span><a href={web} target="_blank" rel="nofollow noopener">{web}</a></span>
										</li>
									}
								</ul>
								{slide ?
									<div className="browser-mockup-wrapper">
										<div className="browser-mockup">
											<Slider {...settings}>
												{slide.map(
													(item, index) =>
													<GatsbyImage
													key={index}
													alt={item.title}
													image={item.gatsbyImageData}
													width={800}
													loading="eager"
													backgroundColor="transparent"
													placeholder="tracedSVG"
												/>
												)}
											</Slider>
										</div>
									</div>
								: null}
								{introduccin ?
								<div className="txt-content text-center" style={{paddingTop:'60px'}}>
									 <Text content={introduccin} />
								</div>
								: null}
							</div>
						</div>
					</div>
				</div>

				<section className="chal-sec-v3" style={{backgroundColor:'#e4edf0'}}>
					<div className="container">
						<div className="row">
							<div className="col-lg-6">
								<div className="mv-title">
									<span><FormattedMessage  id="portfolioChallengeLabel" defaultMessage="Desafíos" /></span>
									<h2>{challengeTitulo}</h2>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="mv-para">
									<Text content={challengeTexto} />
								</div>
							</div>
						</div>
					</div>
				</section>

				{imagenesExplicativas ?
				<section className="pl-v3-slider-sec" style={{backgroundColor:'#e4edf0'}}>
					<div className="pl-v3-slider">
						<Slider {...settings2}>
							{imagenesExplicativas.map(
								(item, index) =>
								<div className="pf-slide-v3" key={index}>
									<GatsbyImage
										alt={item.title}
										image={item.gatsbyImageData}
										width={300}
										loading="eager"
										backgroundColor="transparent"
										placeholder="tracedSVG"
										style={{boxShadow: '0 0.1em 1em 0 rgb(0 0 0 / 40%)'}}
									/>
									{item.title ?<p>{item.title}</p> : null}
								</div>
							)}
						</Slider>
					</div>
				</section>
				: <section style={{backgroundColor:'#e4edf0',paddingTop:'100px'}}></section>}

				<style jsx>{`
					.browser-mockup-wrapper{
						max-width:800px;
						margin:90px auto 0 auto;
					}
					.browser-mockup {
						border-top: 2em solid rgba(230, 230, 230, 0.7);
						box-shadow: 0 0.1em 1em 0 rgba(0, 0, 0, 0.4);
						position: relative;
						border-radius: 3px 3px 0 0
					}
					.browser-mockup:before {
						display: block;
						position: absolute;
						content: '';
						top: -1.25em;
						left: 1em;
						width: 0.5em;
						height: 0.5em;
						border-radius: 50%;
						background-color: #f44;
						box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #9b3, 3em 0 0 2px #fb5;
					}
					.browser-mockup.with-tab:after {
						display: block;
						position: absolute;
						content: '';
						top: -2em;
						left: 5.5em;
						width: 20%;
						height: 0em;
						border-bottom: 2em solid white;
						border-left: 0.8em solid transparent;
						border-right: 0.8em solid transparent;
					}
					.browser-mockup.with-url:after {
						display: block;
						position: absolute;
						content: '';
						top: -1.6em;
						left: 5.5em;
						width: calc(100% - 6em);
						height: 1.2em;
						border-radius: 2px;
						background-color: white;
					}
					.browser-mockup > * {
						display: block;
					}
					.browser-mockup img{
						width:100%;
						height:auto;
					}
				`}</style>
        </Fragment>
    )
}

export default PortfolioFicha