import * as React from "react"
import Layout from "../components/layout/es"
import PortfolioFicha from "../components/portfolio/"


const PortfolioPage = (props) => {

  console.log(props)

  return (
    <Layout transparent location={props.location} pageId="portfolioSingle" metaTitle={props.pageContext.portfolio.title + ' | Crequs'}>
      <PortfolioFicha content={ props.pageContext.portfolio} lang="es-ES" />
    </Layout>
  )
}

export default PortfolioPage
